import React, { useContext } from 'react'
import Link from 'next/link'
import { BASE_URL, imgApiUrl } from '../../../lib/constants'
import { MainContext } from '../../../contexts/MainContext'
import Image from 'next/dist/client/image'
import style from './Category.module.css'

const Category = (props: any) => {
	console.log('props', props)
	const { setIsLoading } = useContext(MainContext)
	const handleClick = (e: any) => {
		if (!(e.ctrlKey || e.metaKey)) {
			setIsLoading(true)
		}
	}

	return (
		<div className='featured-category relative w-full flex flex-col items-center justify-center mt-8 '>
			<Link href={`/${props?.category?.slug}`}>
				<a>
					<div className='cursor-pointer' onClick={handleClick}>
						<div className={`relative ${style.featuredCategoryImgWrapper} p-2  border border-solid border-gray-200 rounded-full overflow:hidden m-auto`}>
							<div className='featured-img absolute'>
								<Image layout='responsive' width={'200px'} height={'200px'} src={props?.category?.image != null ? `${imgApiUrl?.categories?.thumbnail}/${props?.category?.image}` : `${BASE_URL}/img/image.jpg`} sizes='150px' alt={`Thumbnail of ${props?.category?.name}`} />
							</div>
						</div>
						<div className='featured-detail mt-2 text-black text-base text-center'>{props?.category?.name}</div>
					</div>
				</a>
			</Link>
		</div>
	)
}

export default Category
